import { gql } from "@apollo/client";

export const BROKERS = gql`
  query brokers(
    $where: BrokerWhereInput
    $like: BrokerWhereInput
    $take: Int
    $skip: Int
    $orderBy: [BrokerWhereInput]
  ) {
    brokers(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      like: $like
    ) {
      data {
        id
        orias
        socialReason
        code
        organization {
          id
          name
          type
          form
          users {
            id
            gender
            firstname
            lastname
            phone
            meta
          }
        }
      }
      count
    }
  }
`;

export const BROKER = gql`
  query broker($where: BrokerWhereInput) {
    broker(where: $where) {
      id
      orias
      status
      socialReason
      code
      rcsNumber
      sirenNumber
      rcsPlace
      additionalInfo
      distributions {
        id
        insurancePlan {
          id
        }
        commissions {
          id
        }
      }
      organization {
        id
        name
        type
        form
        icon
        telecoms {
          id
          system
          value
        }
        users {
          id
          gender
          firstname
          lastname
          phone
          email
          meta
        }
        contracts {
          id
          subjects
        }
      }
    }
  }
`;

export const BROKERS_WITHOUT_COMMISSION_STATEMENTS = gql`
  query brokersWithoutCommissionStatements(
    $where: BrokerWhereInput
    $take: Int
    $skip: Int
    $orderBy: [BrokerWhereInput]
  ) {
    brokersWithoutCommissionStatements(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      data {
        id
        orias
        socialReason
        code
        organization {
          id
          name
          type
          form
          users {
            id
            gender
            firstname
            lastname
            phone
            meta
          }
        }
      }
      count
    }
  }
`;

const brokers = { BROKERS, BROKER };

export default brokers;
