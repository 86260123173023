import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  MdNumbers,
  MdOutlineAddRoad,
  MdOutlineAlternateEmail,
  MdOutlineCall,
  MdOutlineFlaky,
  MdOutlinePinDrop,
  MdOutlineRestartAlt,
  MdOutlineSouthAmerica,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  REPETITION_INDEX,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Contact = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
  streetType: { options, handleSearch },
  cities,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Form
              type="highlight"
              title={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.ADDRESS.TITLE
              }
            >
              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.NUMBER.LABEL}
                    icon={<MdNumbers size={16} />}
                    placeholder={SUBSCRIPTION.FORM.NUMBER.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Select
                    options={REPETITION_INDEX.map((index) => ({
                      value: index,
                      label: index,
                    }))}
                    icon={<MdOutlineRestartAlt size={16} />}
                    label={SUBSCRIPTION.FORM.REPETITION_INDEX.LABEL}
                    placeholder={SUBSCRIPTION.FORM.REPETITION_INDEX.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    onChange={(value) =>
                      setFieldValue(
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
                        value || null
                      )
                    }
                    allowClear
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Select
                    label={SUBSCRIPTION.FORM.TYPE.LABEL}
                    placeholder={SUBSCRIPTION.FORM.TYPE.PLACEHOLDER}
                    onSearch={handleSearch}
                    showSearch
                    icon={<MdOutlineAddRoad size={16} />}
                    options={options}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.STREET.LABEL}
                    icon={<MdOutlineAddRoad size={16} />}
                    placeholder={SUBSCRIPTION.FORM.STREET.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.COMPLEMENT.LABEL}
                    icon={<MdOutlineAddRoad size={16} />}
                    placeholder={SUBSCRIPTION.FORM.COMPLEMENT.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.POSTAL_CODE.LABEL}
                    icon={<MdOutlinePinDrop size={16} />}
                    placeholder={SUBSCRIPTION.FORM.POSTAL_CODE.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                      values,
                      ...formProps,
                    })}
                    disabled
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Select
                    label={SUBSCRIPTION.FORM.CITY.LABEL}
                    placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
                    icon={<MdOutlineSouthAmerica size={16} />}
                    options={cities.map(({ city }) => ({
                      label: city,
                      value: city,
                    }))}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={24} xs={24}>
            <Form
              type="highlight"
              title={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.PHONE.TITLE
              }
              subtitle={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.PHONE.SUBTITLE
              }
            >
              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Alert
                    showIcon
                    type="info"
                    message={
                      SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.PHONE
                        .INFO
                    }
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.MOBILE.LABEL}
                    icon={<MdOutlineCall size={16} />}
                    placeholder={SUBSCRIPTION.FORM.MOBILE.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                    )}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Input
                    label={SUBSCRIPTION.FORM.LANDLINE.LABEL}
                    icon={<MdOutlineCall size={16} />}
                    placeholder={SUBSCRIPTION.FORM.LANDLINE.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`,
                      values,
                      ...formProps,
                    })}
                    required={isFieldRequired(
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.LANDLINE}.value`
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.EMAIL.LABEL}
              icon={<MdOutlineAlternateEmail size={16} />}
              placeholder={SUBSCRIPTION.FORM.EMAIL.PLACEHOLDER}
              {...bindInputProps({
                name: `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              icon={<MdOutlineFlaky size={16} />}
              label={SUBSCRIPTION.FORM.CONFIRM_CONTACT.LABEL}
              values={[
                {
                  value: true,
                  label: SUBSCRIPTION.FORM.CONFIRM_CONTACT.OPTIONS.YES,
                },
                {
                  value: false,
                  label: SUBSCRIPTION.FORM.CONFIRM_CONTACT.OPTIONS.NO,
                },
              ]}
              {...bindInputProps({
                name: "project.contact.acceptContact",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              onChange={(value) =>
                setFieldValue("project.contact.acceptContact", value)
              }
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Alert
              showIcon
              type="info"
              message={SUBSCRIPTION.FORM.CONFIRM_CONTACT.INFO}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              icon={<MdOutlineFlaky size={16} />}
              label={SUBSCRIPTION.FORM.DIGITAL_OPTION.LABEL}
              values={[
                {
                  value: true,
                  label: SUBSCRIPTION.FORM.DIGITAL_OPTION.OPTIONS.YES,
                },
                {
                  value: false,
                  label: SUBSCRIPTION.FORM.DIGITAL_OPTION.OPTIONS.NO,
                },
              ]}
              {...bindInputProps({
                name: "project.contact.acceptDigital",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              onChange={(value) =>
                setFieldValue("project.contact.acceptDigital", value)
              }
              required={isFieldRequired(
                `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`
              )}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Alert
              showIcon
              type="info"
              message={SUBSCRIPTION.FORM.DIGITAL_OPTION.INFO}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contact;
