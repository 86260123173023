import { gql } from "@apollo/client";

export const ORGANIZATIONS = gql`
  query Organizations(
    $where: OrganizationWhereInput
    $take: Int
    $skip: Int
    $like: OrganizationWhereInput
  ) {
    organizations(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        type
        insurancePlans {
          id
        }
      }
      count
    }
  }
`;

export const ORGANIZATION = gql`
  query Organization($where: OrganizationWhereInput) {
    organization(where: $where) {
      id
      name
      type
      form
      icon
      telecoms {
        id
        system
        value
      }
      users {
        id
        gender
        firstname
        lastname
        phone
        email
        meta
      }
    }
  }
`;

const organizations = { ORGANIZATIONS, ORGANIZATION };

export default organizations;
